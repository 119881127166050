import { Button, Result } from "antd";
import React from "react";
import { useAuthUser } from "../hooks";
import { SecurityIcon } from "../assets/icons";
import { envConfig } from "../config/env";

export const Forbidden = () => {
  const { keycloak } = useAuthUser();

  const doLogout = () => {
    localStorage.clear();
    const keycloakLogoutUrl =
      `${envConfig.viteKeycloakBaseUrl}realms/${
        keycloak.realm
      }/protocol/openid-connect/logout` +
      "?id_token_hint=" +
      keycloak.idToken +
      "&post_logout_redirect_uri=" +
      encodeURIComponent(window.location.origin);
    window.location.replace(keycloakLogoutUrl);
  };

  const paragraphText = `${keycloak.idTokenParsed.email}, You currently do not have access to this platform.\n If you believe you should have access, please contact the Autonomize Support Team for further assistance.`;
  return (
    <div className="forbidden-page">
      <Result
        icon={<SecurityIcon />}
        title="403"
        subTitle="Access Denied"
        extra={[
          <p key="paragraph">{paragraphText}</p>,
          <Button type="primary" key="logoutButton" onClick={doLogout}>
            Logout
          </Button>,
          <Button type="primary" key="backtoHomeButton" onClick={doLogout}>
          go back to homepage
        </Button>,
        ]}
      />
    </div>
  );
};

export default Forbidden;
