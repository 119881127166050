import React, { useState } from 'react';

interface DescriptionProps{
  description:string;
  projectId:string;
}

const RenderDescription = ({description, projectId}:DescriptionProps) => {
  const [expandedDescriptions, setExpandedDescriptions] = useState<any>({});

  const toggleDescription = (id: string) => {
    setExpandedDescriptions((prev:Record<string, boolean>) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
    const words = description.split(" ");
    const isExpanded = expandedDescriptions[projectId];

    if (isExpanded || words.length <= 8) {
      return (
        <div className="flex gp">
          <p dangerouslySetInnerHTML={{ __html: description }} />
          {words.length > 8 && (
            <span
              className="see-more"
              onClick={(e) => {
                e.stopPropagation();
                toggleDescription(projectId);
              }}
            >
              Show less
            </span>
          )}
        </div>
      );
    } else {
      const truncatedDescription = words.slice(0, 8).join(" ") + "...";
      return (
        <div className="flex ">
          <p dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
          <p
            className="see-more justify-end"
            onClick={(e) => {
              e.stopPropagation();
              toggleDescription(projectId);
            }}
          >
            Read more
          </p>
        </div>
      );
    }
  };

  export default RenderDescription