import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, logoutUser, setAuthorDetails, setKeycloakInstance } from "../../store";
import { PAGE_URL } from "../../utils";
import { message } from "antd";
import Keycloak from "keycloak-js";
import { userService } from "../../api";
import { envConfig } from "../../config/env";
import { IAuthDetails } from "../../store/auth-user/types";

// import keycloakJson from '../../keycloak.json';

const keycloakJson = {
  url: envConfig.viteKeycloakBaseUrl,
  realm: envConfig.viteKeycloakRealm,
  clientId: envConfig.viteKeycloakClientId,
};

function Login(): JSX.Element {
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  useEffect(() => {
    const kc: Keycloak = new Keycloak(keycloakJson as Keycloak.KeycloakConfig);
    const doLogin: () => void = kc.login;

    const handleLogout = (idToken: string | undefined, realm: string | undefined) => {
      localStorage.clear();
      if (idToken && realm) {
        const keycloakLogoutUrl =
          `${envConfig.viteKeycloakBaseUrl}realms/${realm}/protocol/openid-connect/logout` +
          "?id_token_hint=" +
          idToken +
          "&post_logout_redirect_uri=" +
          encodeURIComponent(window.location.origin);
        window.location.replace(keycloakLogoutUrl);
      } else {
        window.location.replace(PAGE_URL.LOGIN);
      }
    };

    const getData: (response: Keycloak) => void = async (response) => {
      if (!response.authenticated || !response.idTokenParsed) {
        message.error("Email or password is incorrect");
        return;
      }
    
      const { given_name, family_name, email } = response.idTokenParsed;
      const realmName: string = response.realm || "";
      
      try {
        const user = await userService.getUserByEmail(email);
    
        if (!user || !user.status) {
          handleLogout(response.idToken, response.realm);
          return;
        }
    
        switch (user.status) {
          case 200:{
              const roles = user.data.roles.map((role: any) => role.name);
              const authorDetails:IAuthDetails={
                user_id: user.data.id,
                client_id: user.data.client.id
              }
              dispatch(
                loginUser({
                  id: user.data.id,
                  firstName: given_name,
                  lastName: family_name,
                  email: email,
                  role: roles,
                  orgId: user.data.client.id,
                  realmName: realmName,
                  clientId: user.data.client.id,
                  roles: {
                    list: [],
                    id: 0,
                  }
                }),
              );
              dispatch(setAuthorDetails(authorDetails))
              navigate(roles.length ? PAGE_URL.DASHBOARD : PAGE_URL.FORBIDDEN);
              break;
            }
          case 401:
            handleLogout(response.idToken, response.realm);
            break;
          case 403:
            navigate(PAGE_URL.FORBIDDEN);
            break;
          default:
            handleLogout(response.idToken, response.realm);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        handleLogout(response.idToken, response.realm);
      }
    };

    const initKeyclaok = async () => {
      const isAuthenticated = await kc.init({
        onLoad: "check-sso",
        checkLoginIframe: false,
      });
      if (isAuthenticated) {
        setKeycloak(kc);
        setAuthenticated(isAuthenticated);
        dispatch(setKeycloakInstance(kc));
        localStorage.setItem("accessToken", kc.token ?? "");
        await getData(kc);
      } else {
        console.warn("not authenticated");
        doLogin();
      }
    };
    initKeyclaok();
  }, []);
  return <></>;
}

export default Login;
