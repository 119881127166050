import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useAuthUser = () => {
  const { user, keycloak } = useSelector((state: RootState) => state.authUser);
  const { firstName, lastName, email, role } = user;
  
  return {
    firstName,
    lastName,
    roleName: "",
    email,
    keycloak,
    role
  };
};
