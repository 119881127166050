import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IFileFolderUploadInitialState {
  projectId: string;
  directoryPath: string;
  canFetchFiles: boolean;
  canFetchContainers: boolean;
  targetFileFolder: string;
  isEdit: boolean;
  continuationToken: string;
}

export const fileFolderUploadInitialState: IFileFolderUploadInitialState = {
  projectId: "",
  directoryPath: "",
  canFetchFiles: false,
  canFetchContainers: true,
  targetFileFolder: "",
  isEdit: false,
  continuationToken: "",
};

export const FileFolderUploadSlice = createSlice({
  name: "fileFolderUpload",
  initialState: fileFolderUploadInitialState,
  reducers: {
    updateDirectoryPath: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.directoryPath = payload;
    },

    updateCanFetchFiles: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchFiles = payload;
    },
    updateContinuationToken: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.continuationToken = payload;
    },
    updateCanFetchContainers: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchContainers = payload;
    },

    updateTargetFileFolder: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.targetFileFolder = payload;
    },

    updateFolderIsEditCheck: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEdit = payload;
    },

    updateProjectId: (state: IFileFolderUploadInitialState, { payload }: PayloadAction<string>) => {
      state.projectId = payload;
    },
  },
});

export const {
  updateCanFetchFiles,
  updateDirectoryPath,
  updateFolderIsEditCheck,
  updateTargetFileFolder,
  updateCanFetchContainers,
  updateProjectId,
  updateContinuationToken,
} = FileFolderUploadSlice.actions;

export default FileFolderUploadSlice.reducer;
