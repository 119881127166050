import { useCallback, useState } from "react";
import { evidenceViewerService, EvidencePageResponseInfo, SearchOccurrence, IRepsonseWrapper } from "../api";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { RootState } from "../store";
import { useSelector } from "react-redux";

interface Props {
  searchKeywords: string | null;
  documentUuid: string;
  signedUrl: string;
}

const filename = ""; // todo: need to change once api is done

export const useFetchEvidenceViewer = ({ searchKeywords, documentUuid, signedUrl }: Props) => {
  const [currPage, setCurrPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [searchOccurenceLoading, setSearchOccurenceLoading] = useState(true);
  const [pageData, setPageData] = useState({} as EvidencePageResponseInfo);
  const [isTiffFile, setIsTiffFile] = useState(false);
  const [occurencesList, setOccurencesList] = useState([] as SearchOccurrence[]);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const {currentPage}=useSelector((state: RootState) => state.document);

  const fetchPage = useCallback(async () => {
    try {
      setPageLoading(true);
      const  response = await evidenceViewerService.getPageData(currentPage, documentUuid);
      const data : EvidencePageResponseInfo = response.data
      setPageData(data);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        //openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setPageLoading(false);
    }
  }, [currPage, filename,currentPage]);

  const fetchSearchOccurences = useCallback(
    async (keyword: string | null) => {
      try {
        setCurrPage(currentPage);
        setSearchOccurenceLoading(true);
        const { data } = await evidenceViewerService.getOccurrencesPages(
          documentUuid,
          keyword,
        );
        const result=data.data
        .sort((a, b) => a.pageNumber - b.pageNumber)
        .filter((item) => item.pageNumber !== 0)
        setOccurencesList(
          result
        );
        return result;
      } catch (err: any) {
        if (err.response.data.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setSearchOccurenceLoading(false);
      }
    },
    [filename, searchKeywords]
  );

  return {
    fetchPage,
    currPage,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    setCurrPage,
    fetchSearchOccurences,
    occurencesList,
    isTiffFile,
    isAuthorized,
    setIsAuthorized,
  };
};
