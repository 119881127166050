import React, { FormEvent, useState } from "react";
import { Drawer, Flex, Tooltip } from "antd";
import "./ask-auto.scss";
import {
  MessageFilled,
  DeleteOutlined,
  SendOutlined,
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import AskAutoQuestion from "./components/question/AskAutoQuestion";
import AskAutoResponse from "./components/response/AskAutoResponse";
import { IAskAutoChatDataRes, priorAuthService } from "../../api";
import { openNotificationWithIcon } from "../../utils";
import { ChatIcon } from "../../assets/icons";
import { QAHistory } from "./components/QAHistory";

const PRESET_PROMPTS = [
  {
    q: "Summarize quality care opportunities",
    desc: "Identify pertinent opportunities member is likey to close",
  },
  {
    q: "Suggest adjacent medical services needed",
    desc: "Summarize likelihood of downstream to bundle prior auth requests",
  },
];

const AskAuto = ({ buttonClassName, documentUuid, projectId }: Props) => {
  const [showAskAuto, setShowAskAuto] = useState(false);
  const [query, setQuery] = useState("");
  const [responses, setResponses] = useState<IAskAutoChatDataRes[]>([]);
  const [loading, setLoading] = useState({ query: false });
  const [showPrompts, setShowPrompts] = useState(false);
  const [showRightSection, setShowRightSection] = useState(false);

  const toggleAskAuto = () => setShowAskAuto((prev) => !prev);

  const handleFormOnSubmit = async (e?: FormEvent | null, questionToRedo?: string) => {
    if (loading.query) return null;
    try {
      e?.preventDefault();
      const questionToAsk = questionToRedo || query || responses[0]?.query;

      if (questionToRedo) {
        setQuery(questionToRedo);
      }

      setLoading((prev) => ({ ...prev, query: true }));
      const {
        data: { data },
      } = await priorAuthService.fetchAnswerForAskAuto(questionToAsk, documentUuid, projectId);
      setResponses((prev) => [data, ...prev]);

      setQuery("");
    } catch (error: any) {
      openNotificationWithIcon(error?.message || "Please try again", "", "error");
    } finally {
      setLoading((prev) => ({ ...prev, query: false }));
    }
  };

  const handleSubmitPreset = async (prompt: string) => {
    if (loading.query) return null;
    setQuery(prompt);
    setShowPrompts(false);
    await handleFormOnSubmit(null, prompt);
  };

  const handleDeleteResponses = () => setResponses([]);

  const ExtraHeaderActions = () => {
    return (
      <div className="ask-auto-header-actions">
        <span className="action" onClick={() => setShowRightSection(!showRightSection)}>
          <HistoryOutlined />
          <span className="action-text">{showRightSection ? "Clear" : "History"}</span>
        </span>
        <span className="action" onClick={handleDeleteResponses}>
          <DeleteOutlined />
          <span className="action-text">Clear</span>
        </span>
        <CloseOutlined className="action" onClick={toggleAskAuto} />
      </div>
    );
  };

  const Title = () => {
    return (
      <div className="ask-auto-header-title">
        <ChatIcon width={30} />
        <h3>Ask Auto</h3>
      </div>
    );
  };

  return (
    <div className="ask-auto">
      <MessageFilled className={buttonClassName || "ask-auto-button"} onClick={toggleAskAuto} />
      <Drawer
        maskClosable={false}
        open={showAskAuto}
        closeIcon={false}
        onClose={toggleAskAuto}
        title={<Title />}
        placement="right"
        size="large"
        extra={<ExtraHeaderActions />}
        className="ask-auto-container"
      >
        <Flex className="h-full">
          <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
            <div className="responses">
              {responses?.map((response: IAskAutoChatDataRes, idx: number) => (
                <div key={`response_${response.qaId}`} className="responses-single">
                  <AskAutoQuestion key={`question_${response.query}`} query={response?.query} />
                  <AskAutoResponse
                    response={response}
                    lastResponse={!idx}
                    redoQuestion={() => handleFormOnSubmit(null, response.query)}
                  />
                </div>
              ))}
            </div>
            <div className="ask-auto-prompts-container">
              <div
                className={`ask-auto-prompts-toggle ${
                  showPrompts ? "" : "ask-auto-prompts-toggle-short"
                }`}
                onClick={() => setShowPrompts((prev) => !prev)}
                title="Prompts"
              >
                {!showPrompts ? <UpOutlined /> : <DownOutlined />}
              </div>
              <div className="ask-auto-prompts">
                {showPrompts &&
                  PRESET_PROMPTS.map((promptObj) => (
                    <div
                      className="ask-auto-prompts-single"
                      key={promptObj.q}
                      onClick={() => handleSubmitPreset(promptObj.q)}
                    >
                      <p>{promptObj.q}</p>
                      <i>{promptObj.desc}</i>
                    </div>
                  ))}
              </div>
            </div>
            <form onSubmit={(event) => handleFormOnSubmit(event)}>
              <Tooltip title={loading.query ? "Please wait while answer is loading" : ""}>
                <input
                  placeholder="Ask your question"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                  disabled={loading.query}
                />
              </Tooltip>
              <button disabled={loading.query}>
                {!loading.query ? <SendOutlined /> : <LoadingOutlined />}
              </button>
            </form>
            <p className="ask-auto-warning">
              Ask Auto can make mistakes. Consider checking important information.
            </p>
          </div>
          {showRightSection && <QAHistory />}
        </Flex>
      </Drawer>
    </div>
  );
};

export default AskAuto;

type Props = {
  buttonClassName?: string;
  documentUuid: string;
  projectId: string;
};
