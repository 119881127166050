import React, { useEffect, useState } from "react";
import { PdfViewer } from "../../components";
import { Button, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { IGetSignedURLForFullSourceResponse, uploadFileFolderService } from "../../api";
import { openNotificationWithIcon } from "../../utils";
import "./case-details-container.scss";
import {
  DiagnosisProps,
  GuidelineCompliance,
  PatientSummary,
  PriorAuthorization,
  ProcedureProps,
} from "../../containers";
import PatientSummaryNew from "../patient-summary/PatientSummaryNew";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setSignedUrl, setSummaryType } from "../../store";

type Props = {
  pageData: {
    key: React.Key;
    fileFolder: string;
    createdOn: string;
    updatedOn: string;
    status: string;
    action: string;
    isFolder: boolean;
    name: string;
    documentUUID: string;
    id: string
  };
  projectId: string;
  documentUUID: string;
};

const documentsWithHardCodedValues = [
  "Enrolee Info.pdf",
  "PBMCase1.pdf",
  "PBMCase2.pdf",
  "PBMCase3.pdf",
  "PBMCase4.pdf",
  "146132852320240319.pdf",
  "188685422520240319.pdf",
  "156116407220240319.pdf",
  "165882770520240319.pdf",
  "198485391920240319.pdf",
  "Tenoudji 91213 0524.pdf",
  "FX037042.pdf",
  "Billing Document - 8007307259.pdf",
  "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf",
  "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf",
  "Invoice-2024-83163-00.pdf"
];

export const CaseDetailsContainer = ({ pageData, projectId, documentUUID }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [tabDisabled, setTabDisabled] = useState({ "1": false, "2": true, "3": true });
  const [procCode, setProcCode] = useState<ProcedureProps>({
    code: "",
    description: "",
  });
  const [diagCode, setDiagCode] = useState<DiagnosisProps>({
    code: "",
    description: "",
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([] as string[] | undefined);
  const { projectDetails } = useSelector((state: RootState) => state.document);

  const dispatch = useDispatch();

  const navigateToTab = (
    tabKey: string,
    procedureCode?: any,
    diagnosisCode?: any,
    checboxes?: string[]
  ) => {
    if (procedureCode) {
      setProcCode(procedureCode);
    }
    if (diagnosisCode) {
      setDiagCode(diagnosisCode);
    }
    setActiveTab(tabKey);
    setTabDisabled((prev) => ({ ...prev, [tabKey]: false }));
    setSelectedCheckboxes(checboxes);
  };

  const getSignedUrlByPath = async () => {
    try {
      const response = await uploadFileFolderService.getSignedUrlByFilepath(
        pageData.name,
        projectDetails.source_details
      );
      const data = response.data as IGetSignedURLForFullSourceResponse;
      setFileUrl(data.presignedUrl.data.signedUrl);
      dispatch(setSignedUrl(data.presignedUrl.data.signedUrl));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getSignedUrlByPath();
  }, []);

  useEffect(() => {
    const parentElem = document.querySelector(".tabView");
    if (parentElem) {
      parentElem.scrollIntoView({ behavior: "smooth", block: "start" });
      window.scrollTo(0, 0);
      parentElem.scrollTo(0, 0);
    }
  }, [activeTab]);

  const handleTabsChange = (key:string) => {
    setActiveTab(key);
    switch (key) {
      case "1":
        dispatch(setSummaryType("Patient Summary"));
        break;
      case "2":
        dispatch(setSummaryType("Guidelines Summary"));
        break;
      case "3":
        dispatch(setSummaryType("Recommendation Summary"));
        break;
    }
  }

  return (
    <div className="case-details-wrapper">
      <div style={{ flex: 1, flexBasis: "10vw", width: "40%" }}>
        <PdfViewer
          currPage={currentPage}
          pdf={fileUrl}
          displayPagination
          setCurrPage={setCurrentPage}
          sourcePopup={false}
          fileName={pageData.name}
        />
      </div>
      <Tabs
        centered
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={handleTabsChange}
        tabBarGutter={48}
        className="tabView"
      >
        <TabPane
          tab={
            <div className="tabView-title">
              <span>1</span> Patient Summary
            </div>
          }
          key="1"
        >
          {documentsWithHardCodedValues.find(item => item === pageData.name) ? (
            <PatientSummaryNew
              documentUUID={documentUUID}
              projectId={projectId}
              enableNextTab={(procedureCode, diagnosisCode) =>
                navigateToTab("2", procedureCode, diagnosisCode)
              }
              documentName={pageData.name}
            />
          ) : (
            <PatientSummary
              documentUUID={documentUUID}
              projectId={projectId}
              enableNextTab={(procedureCode, diagnosisCode) =>
                navigateToTab("2", procedureCode, diagnosisCode)
              }
              pdf={fileUrl}
              fileName={pageData.name}
            />
          )}
        </TabPane>
        <TabPane
          tab={
            <div className="tabView-title" onClick={()=>dispatch(setSummaryType("Guidelines Summary"))}>
              <span>2</span> Guideline Compliance
            </div>
          }
          key="2"
          disabled={tabDisabled["2"]}
        >
          <GuidelineCompliance
            documentUUID={documentUUID}
            projectId={projectId}
            enableNextTab={(checkboxes: string[]) =>
              navigateToTab("3", procCode, diagCode, checkboxes)
            }
            procedureCode={procCode}
            diagnosisCode={diagCode}
            fileName={pageData.name}
            />
        </TabPane>
        <TabPane
          tab={
            <div className="tabView-title">
              <span>3</span> Recommendation
            </div>
          }
          key="3"
          disabled={tabDisabled["3"]}
        >
          <PriorAuthorization
            pageData={pageData}
            projectId={projectId}
            procedureCode={procCode}
            diagnosisCode={diagCode}
            selectedCheckboxes={selectedCheckboxes}
            fileName={pageData.name}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
