import { Card, Tooltip } from "antd";
import { IProject } from "../../interfaces/project";
import { ProjectActions } from "../../pages/dashboard/ProjectActions";
import { getLocalDateTimeStringFromISO } from "../../utils";
import { Loading } from "../Loading";
import React, { useState } from "react";
import "./render-projects.scss";
import RenderDescription from "./RenderDescription";

interface Props {
  handleProjectClick: (project: IProject) => void;
  sortedProjects: IProject[];
  loading: boolean;
}

const RenderProjectInCards: React.FC<Props> = ({
  handleProjectClick,
  sortedProjects,
  loading,
}: Props) => {
  if (loading) {
    return <Loading />;
  }
  if (sortedProjects?.length === 0) {
    return (
      <div className="projects-not-found">
        <p className="text">No Projects Found</p>
      </div>
    );
  }
  return (
    <div className="project-card-grid main-card-container">
      {sortedProjects?.map((project) => (
        <div key={`${project.id}`}>
          <Card
            className="cursor-pointer card-height p-20"
            onClick={() => {
              handleProjectClick(project);
            }}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <div className="flex jcsb m-b link-color">
              <h3 className="project-name link-color">{project.name}</h3>
              <ProjectActions project={project} />
            </div>
            <div className="cursor-pointer project-card-footer flex flex-column m-b">
              <div className="project-card-view-dis cursor-pointer ">
              <h5 className="guidelines">Guidelines:</h5><div>{project?.guidelines[0]?.name}</div>
                <h5 title={project.description}>Project Description:</h5>
                <Tooltip
                  placement="topRight"
                  title={<span className="project-tooltip">{project.description}</span>}
                  overlayInnerStyle={{ backgroundColor: "white", color: "#1e293b" }}
                >
                  <RenderDescription description={project.description} projectId={project.id} />
                </Tooltip>
              </div>
            </div>

            <div className="flex jcsb aic pt-20 mt-auto">
              <p>
                <span>Created on </span>
                <span className="highlight">
                  {getLocalDateTimeStringFromISO(project.created_at)}
                </span>
              </p>
              <p>
                <span>Updated on </span>
                <span className="highlight">
                  {getLocalDateTimeStringFromISO(project.updated_at)}
                </span>
              </p>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default RenderProjectInCards;
