import { IRepsonseWrapper, apiClient } from ".";
import {
  ICreateProject,
  IEditProjectPayload,
  IProject,
  ProjectDetailResponse,
} from "../interfaces/project";

const getProjects = async (searchTerm: string, sortBy: string, sortOrder: number) => {
  const queryParams: { [key: string]: string | number } = {
    sortBy,
    sortOrder,
  };
  if (searchTerm) {
    queryParams["searchTerm"] = searchTerm;
  }
  const res = await apiClient.get(`/projects`, {
    params: queryParams,
  });
  return res;
};

const getProject = async (id: number) => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectDetailResponse>>(`projects/${id}`);
  return res;
};

const getGenerativeAiModel = async () => {
  const res = await apiClient.get(`/llm-models`);
  return res;
};

const getGuidelines = async () => {
  const res = await apiClient.get(`/guidelines`);
  return res;
};

const deleteProject = async (id: string) => {
  const res = await apiClient.delete<IRepsonseWrapper<IProject>>(`/projects/${id}`);
  return res;
};

const pinProject = async (id: string, isPinned: boolean, userId: number) => {
  const res = await apiClient.post<IRepsonseWrapper<IProject>>(
    `/projects/${id}/pin`,
    { userId },
    { params: { isPinned } }
  );
  return res;
};

const createProject = async (payload: ICreateProject) => {
  const res = await apiClient.post<IRepsonseWrapper<IProject>>(`/projects`, payload);
  return res;
};

const editProject = async (id: number, payload: IEditProjectPayload) => {
  const res = await apiClient.put<IRepsonseWrapper<IProject>>(`/projects/${id}`, payload);
  return res;
};

export const projectService = {
  getProjects,
  getProject,
  deleteProject,
  createProject,
  getGenerativeAiModel,
  getGuidelines,
  pinProject,
  editProject,
};
