import React, { ReactNode, useCallback, useEffect, useState } from "react";
import "./patient-summary.scss";
import { Button, Dropdown, Menu, Modal } from "antd";
import {
  CloseOutlined,
  CopyOutlined,
  DislikeOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { DiagAndProcCodes, IGetSignedURLForFullSourceResponse, patientSummaryService, uploadFileFolderService } from "../../api";
import { ReactTyped } from "react-typed";
import { MODAL_TYPE, camelToTitleCase, openNotificationWithIcon } from "../../utils";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CodesPopup } from "./CodesPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  RootState,
  setFeedbackType,
  setPatientsSummary,
  setSummaryId,
  setSummaryType,
} from "../../store";
import { handlePdfDownload, handleDocxDownload, handleCopyToClipboard } from "../../utils";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import {
  setCurrentChunkUUID,
  setDocumentUuid,
  setCurrentPage as setGlobalCurrentPage,
} from "../../store/document/slice";
import Markdown from "react-markdown";
import ReactDOMServer from 'react-dom/server';

export type ProcedureProps = {
  code?: string;
  description?: string;
};

export type DiagnosisProps = {
  code?: string;
  description?: string;
};

type Props = {
  enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
  documentUUID: string;
  projectId: string;
  pdf: string;
  fileName: string;
};

interface Procedure {
  code: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  description: string;
}

interface Diagnosis {
  code: string;
  diagnosisQualifier: string;
  description: string;
}

interface PatientData {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | any;
}

interface Offset {
  begin: number;
  end: number;
}

interface IPage {
  chunkUUID: string;
  offsets: Offset[];
  pageNo: number;
}

interface ISources {
  category: string;
  documentUUID: string;
  pages: IPage[];
}

const patientDataKeys = Object.keys({
  acuity: "",
  admissionDateTime: "",
  authorizationID: "",
  dateOfBirth: "",
  dischargeDateTime: "",
  memberID: "",
  memberName: "",
  placeOfService: "",
  requestingProviderName: "",
  requestingProviderFaxNo: "",
  requestingProviderNPI: "",
  servicingProviderName: "",
  servicingProviderNPI: "",
  typeOfService: "",
  state: "",
  procedures: [],
  diagnosis: [],
});

export const PatientSummary = ({
  enableNextTab,
  documentUUID,
  projectId,
  pdf,
  fileName,
}: Props) => {
  const [showPatientSummary, setShowPatientSummary] = useState(false);
  const [expandPatientData, setExpandPatientData] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureProps>({
    code: "",
    description: "",
  });
  const [selectedDiagnosisCode, setSelectedDiagnosisCode] = useState<DiagnosisProps>({
    code: "",
    description: "",
  });
  const [patientSummary, setPatientSummary] = useState("");
  const [caseInfo, setCaseInfo] = useState({} as PatientData);
  const [diagAndProcCodes, setDiagAndProcCodes] = useState([] as DiagAndProcCodes[]);
  const [timeToProcess, setTimeToProcess] = useState<number>(0);
  const [extractedFieldCount, setExtractedFieldCount] = useState<number>(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSummaryGenerated, setIsSummaryGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sources, setSources] = useState<ISources[]>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [chatSource, setChatSource] = useState({} as ISources);
  const { projectDetails } = useSelector((state: RootState) => state.document);
  const [typedText, setTypedText] = useState('');

  const formatSummary = (text:any) => {
    return text.replace(/&/g, "&amp;");
  };

  const markdownToString = (markdown:any) => {
    const reactElement = <Markdown>{markdown}</Markdown>;
    return ReactDOMServer.renderToString(reactElement);
  };

  useEffect(() => {
    const formattedText = formatSummary(patientSummary);
    const htmlString = markdownToString(formattedText);
    setTypedText(htmlString);
  }, [patientSummary]);

  const getSourceNumberMap = (sourcesNew: ISources[]) => {
    const map: {
      [key: string]: { source: ISources; sourceNumber: number; pageNo: number; chunkUUID: string };
    } = {};
    sourcesNew.forEach((source, index) => {
      source.pages.forEach((page) => {
        map[page.chunkUUID] = {
          source,
          sourceNumber: index + 1,
          pageNo: page.pageNo,
          chunkUUID: page.chunkUUID,
        };
      });
    });
    return map;
  };

  const handleSourceClick = async (source: ISources, pageNumber?: number, chunkUUID?: string) => {
    setFileUrl("");
    setChatSource(source);
    dispatch(setGlobalCurrentPage(pageNumber || 1));
    dispatch(setCurrentChunkUUID(chunkUUID || ""));
    try {
      const response = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
      const data = response.data as IGetSignedURLForFullSourceResponse;
      setFileUrl(data.presignedUrl.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const renderAnswer = useCallback(
    (patientSummaryNew: string, source: ISources[]): any => {
      const sourceNumberMap = getSourceNumberMap(source);
      const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
      const parts = patientSummaryNew.split(uuidRegex).map((part) =>
        part
          .trim()
          .replace(/^[[\]]/, "")
          .replace(/[[\]]$/, "")
      );
      const chunkUUIDs = patientSummaryNew.match(uuidRegex) || [];
      const processedData = parts.map((part, index) => {
        const uuid = chunkUUIDs[index];
        if (uuid && sourceNumberMap[uuid]) {
          const sourceInfo = sourceNumberMap[uuid];
          const sortedSourceNumbers = [sourceInfo.sourceNumber].sort((a, b) => a - b);
          return (
            <span key={index} style={{ fontSize: "16px" }}>
              {part}
              <span
                className="citation-number"
                onClick={() =>
                  handleSourceClick(source[0], sourceInfo.pageNo, sourceInfo.chunkUUID)
                }
              >
                [{sortedSourceNumbers}]
              </span>
            </span>
          );
        }
      });
      return (
        <div>{processedData}</div>
        // <ReactTyped
        // strings={[processedData]}
        // typeSpeed={1}
        // showCursor={false}
        // style={{ fontSize: "16px" }}
        // />
      );
    },
    [sources, patientSummary]
  );

  const getCaseInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await patientSummaryService.getCaseInfo(documentUUID);
      setCaseInfo(data.key_value_data);
      //setDiagAndProcCodes(data.data.diagAndProcCodes);
      setTimeToProcess(data.data.timeToProcess);
      setExtractedFieldCount(data.data.extractedFieldCount);
    } catch (err: any) {
      openNotificationWithIcon("", err?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCaseInfo();
  }, []);

  const handleClose = () => {
    setShowCodePopup(false);
  };

  const handleConfirmCode = async (diagnoses?: DiagnosisProps, procedure?: ProcedureProps) => {
    if (isLoading) return;
    try {
      handleClose();
      setIsSummaryLoading(true);
      setIsLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,procedure,diagnoses
        // diagnoses ? caseInfo.diagnoses : selectedDiagnosisCode,
      );
      setPatientSummary(data.summary || data.message || "Summary not found");
      setSources(data?.data?.sources);
      dispatch(setPatientsSummary(data?.summary));
      dispatch(setSummaryId(data?.id));
      dispatch(setDocumentUuid(data?.document_id));
      setShowPatientSummary(true);
      setExpandPatientData(false);
      setIsSummaryGenerated(true);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
      setIsSummaryLoading(false);
    }
  };

  const handleGeneratePatientSummary = () => {
    if (isLoading) return;
    dispatch(setSummaryType("Patient Summary"));
    if (!caseInfo?.diagnosis?.length || !caseInfo?.procedures?.length) {
      openNotificationWithIcon("", "No diagnosis and procedure present", "error")
      //setShowCodePopup(true);
    } else if(caseInfo?.diagnosis?.length && caseInfo?.procedures?.length) {
      // const splitCodes = diagAndProcCodes.reduce(
      //   (acc: any, code) => {
      //     if (code.diagnosisCode) {
      //       acc.diagnosisCodes.push({
      //         diagnosisCode: code.diagnosisCode,
      //         diagnosisDescription: code.diagnosisDescription,
      //       });
      //     } else if (code.procedureCode) {
      //       acc.procedureCodes.push({
      //         procedureCode: code.procedureCode,
      //         procedureDescription: code.procedureDescription,
      //       });
      //     }
      //     return acc;
      //   },
      //   { diagnosisCodes: [], procedureCodes: [] }
      // );
      // const { diagnosisCodes, procedureCodes } = splitCodes;
      setSelectedProcedureCode({
        code: caseInfo.procedures[0].code,
        description: caseInfo.procedures[0].description,
      });
      setSelectedDiagnosisCode({
        code: caseInfo.diagnosis[0].code,
        description:caseInfo.diagnosis[0].description,
      });
      handleConfirmCode(
        {
          code: caseInfo.diagnosis[0].code,
          description: caseInfo.diagnosis[0].description,
        },
        {
          code: caseInfo.procedures[0].code,
        description: caseInfo.procedures[0].description,
        }
      );
    }
  };

  const handleGenerateCompliance = () => {
    enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
    dispatch(setSummaryType("Guidelines Summary"));
  };

  const formatKey = (key: string) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const downloadPDF = async () => {
    try {
      const response = await fetch(pdf);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      openNotificationWithIcon("", "Downlaod Failed", "error");
    }
  };

  const handleThumbsClick = (type: "up" | "down") => {
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="pdf"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handlePdfDownload(patientSummary, "Patient Summary", fileName)}
      >
        Download as PDF
      </Menu.Item>
      <Menu.Item
        key="docx"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handleDocxDownload(patientSummary, "Patient Summary", fileName)}
      >
        Download as Docx
      </Menu.Item>
      <Menu.Item
        key="copy"
        icon={<CopyOutlined />}
        className="export-dropdown-item"
        onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
      >
        Copy to Clipboard
      </Menu.Item>
    </Menu>
  );

  const renderNotExpandedPatientData = () => (
    <>
      {caseInfo ? !expandPatientData && Object.keys(caseInfo).length > 0 && (
        <div className="single-info grid grid-cols-2">
          <div className="patientData-info">{formatKey(Object.keys(caseInfo)[0])}</div>
          {
            <div className="fz-16 p-block">
              {Array.isArray(caseInfo[Object.keys(caseInfo)[0]])
                ? caseInfo[Object.keys(caseInfo)[0]][0]?.procedureCode
                : caseInfo[Object.keys(caseInfo)[0]]}
            </div>
          }
        </div>
      ):""}
    </>
  );

  const renderExpandedPatientData = () => (
    <>
      {expandPatientData && (
        <>
          {caseInfo &&
            Object.entries(caseInfo)
              .filter(([key]) => patientDataKeys.includes(key))
              .map(([key, value], index) => {
                return (
                  <div key={index}>
                    {key !== "procedures" && key !== "diagnosis" && (
                      <div className="single-info grid grid-cols-2">
                        <div className="patientData-info">{formatKey(key)}</div>
                        <div className="fz-16 p-block">{value}</div>
                      </div>
                    )}
                    {key === "procedures" && (
                      <>
                        {value.map((procedure: Procedure, procedureIndex: number) => (
                          <div
                            key={`procedure-${procedureIndex}`}
                            className="single-info grid grid-cols-2"
                          >
                            <div className="patientData-info">Procedure {procedureIndex + 1}</div>
                            <div className="fz-16 p-block">
                              {procedure?.code && (
                                <div>{`Procedure Code: ${procedure?.code}`}</div>
                              )}
                              {procedure?.dateOfServiceStart && (
                                <div>{`Date of Service Start: ${procedure?.dateOfServiceStart}`}</div>
                              )}
                              {procedure?.dateOfServiceEnd && (
                                <div>{`Date of Service End: ${procedure?.dateOfServiceEnd}`}</div>
                              )}
                              {procedure?.description && (
                                <div>{`Procedure Description: ${procedure?.description}`}</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {key === "diagnosis" && (
                      <>
                        {value.map((diagnosis: Diagnosis, diagnosisIndex: number) => (
                          <div
                            key={`diagnosis-${diagnosisIndex}`}
                            className="single-info grid grid-cols-2"
                          >
                            <div className="patientData-info">Diagnosis {diagnosisIndex + 1}</div>
                            <div className="fz-16 p-block">
                              {diagnosis.code && (
                                <div>{`Diagnosis Code: ${diagnosis?.code}`}</div>
                              )}
                              {diagnosis.diagnosisQualifier && (
                                <div>{`Diagnosis Qualifier: ${diagnosis.diagnosisQualifier}`}</div>
                              )}
                              {diagnosis.description &&
                                !diagnosis.description.includes("unspecified") && (
                                  <div>{`Diagnosis Description: ${diagnosis.description}`}</div>
                                )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                );
              })}
        </>
      )}
    </>
  );

  const renderMedicalHistorySources = (): ReactNode => {
    return (
      <div className="source-container">
        <h5>Sources: </h5>
        <div className="source-list flex gp-10">
          {!!sources?.length &&
            sources?.map((source, i) => {
              return (
                <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                  <a>{source?.category}[1]</a>
                </li>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="patientData">
        <div className="patientData-info">
          {`Time to process: ${(timeToProcess / 1000).toFixed()} secs  |  Extracted fields count: ${
            caseInfo ? extractedFieldCount == 0 ? Object.keys(caseInfo).length : extractedFieldCount : ""
          }`}
        </div>
        <div
          className={`patientData-patientInfo  ${
            patientSummary
              ? `patientData-patientInfo-${expandPatientData ? "expanded" : "short"}`
              : "show-full"
          }`}
        >
          {renderNotExpandedPatientData()}

          {renderExpandedPatientData()}
        </div>

        {!expandPatientData ? (
          <div className="patientData-downicon">
            <DownCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        ) : (
          <div className="patientData-upicon">
            <UpCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        )}
        {showPatientSummary ? (
          isSummaryLoading ? (
            <Loading />
          ) : (
            <div className="patientData-patientSummary">
              <h2 className="patientData-info">Patient Summary</h2>
              {sources?.length == 0 || sources?.length == undefined ? (
                <ReactTyped
                  strings={[typedText]}
                  typeSpeed={1}
                  showCursor={false}
                  style={{ fontSize: "16px" }}
                />
              ) : (
                <>
                  <div className="row">
                    <div className="value">{renderAnswer(patientSummary, sources)}</div>
                  </div>
                  {renderMedicalHistorySources()}
                  {!!Object.keys(sources).length && (
                    <SourcePopup
                      chatSource={chatSource}
                      setChatSource={setChatSource}
                      fileUrl={fileUrl}
                      guideline={projectDetails.guidelines}
                    />
                  )}
                </>
              )}
            </div>
          )
        ) : (
          <div className="patientData-actions">
            <div className="actions">
              {isSummaryGenerated && (
                <>
                  <AskAuto projectId={projectId} documentUuid={documentUUID} />
                  <LikeOutlined
                    className="cursor-pointer flex ask-auto-button"
                    onClick={() => handleThumbsClick("up")}
                  />
                  <DislikeOutlined
                    className="cursor-pointer flex ask-auto-button"
                    onClick={() => handleThumbsClick("down")}
                  />
                </>
              )}
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <Button
                className="fill"
                style={{ float: "right" }}
                onClick={handleGeneratePatientSummary}
                disabled={isLoading}
              >
                Generate Patient Summary
              </Button>
            )}
          </div>
        )}

        {showPatientSummary && (
          <div className="patientData-footer">
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
              <LikeOutlined
                className="cursor-pointer flex action"
                onClick={() => handleThumbsClick("up")}
              />
              <DislikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("down")}
              />
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              <Button className="fill" onClick={handleGenerateCompliance}>
                Generate Guideline Compliance
              </Button>
            </div>
          </div>
        )}
      </div>
      {showCodePopup && !isLoading && (
        <CodesPopup
          {...{
            showCodePopup,
            setShowCodePopup,
            diagAndProcCodes,
            setSelectedProcedureCode,
            setSelectedDiagnosisCode,
            handleConfirmCode,
          }}
        />
      )}
    </>
  );
};

export default PatientSummary;
