import React, { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { qaService } from "../../../api/qa";
import { Loading } from "../../Loading";
import { Card } from "antd";

export const QAHistory = () => {
  const [fetchingHistory, setFetchingHistory] = useState(true);
  const [historyList, setHistoryList] = useState<any[]>([]);

  const { user } = useSelector((state: RootState) => state.authUser);

  const fetchChatsHistory = async () => {
    setFetchingHistory(true);
    try {
      const res = await qaService.fetchQAHistory(user.id);
      setHistoryList(res);
    } catch (error) {
      openNotificationWithIcon("", "Error While Fetching History!", "error");
    } finally {
      setFetchingHistory(false);
    }
  };

  useEffect(() => {
    fetchChatsHistory();
  }, []);

  const renderComponent = () => {
    // <div
    //   style={{
    //     border: "1px solid #E7ECF4",
    //     borderRadius: "4px",
    //     padding: "8px 10px",
    //     fontSize: "12px",
    //     fontWeight: "500",
    //     backgroundColor: "#F7F7F9",
    //     color: "#3232A6",
    //   }}
    // >
    //   What are the current billing and coding practices as per cms guidelines?
    // </div>;
    // <div
    //   style={{
    //     border: "1px solid #E7ECF4",
    //     borderRadius: "4px",
    //     padding: "8px 10px",
    //     color: "#475569",
    //     fontSize: "12px",
    //     fontWeight: "500",
    //   }}
    // >
    //   What are the eligibility requirements?
    // </div>;
    if (fetchingHistory) return <Loading />;
    if (historyList.length === 0) return "No History Found";
    return historyList.map((history: any, index: number) => {
      return (
        <div
          style={{
            border: "1px solid #E7ECF4",
            borderRadius: "4px",
            padding: "8px 10px",
            fontSize: "12px",
            fontWeight: "500",
            backgroundColor: "#F7F7F9",
            color: "#3232A6",
          }}
          key={`history-${index}`}
        >
          {history.question}
        </div>
      );
    });
  };

  return (
    <div
      style={{
        maxWidth: "300px",
        padding: "20px",
        flex: "1",
        borderLeft: "1px solid #E7ECF4",
        marginLeft: "20px",
        gap: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderComponent()}
    </div>
  );
};
