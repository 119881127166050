import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Progress, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { openNotificationWithIcon } from "../../utils";
import "./uploadFiles.scss";
import { uploadFileFolderService } from "../../api";
import { useFetchDocuments } from "../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
  projectId?: string;
  loading: boolean;
  setLoading: any;
}
const acceptedFormats = [".pdf",".tiff"];

export const FileUploader = ({ projectId, loading, setLoading }: Props) => {
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [pollingCounter, setPollingCounter] = useState<number>(0);
  const { fetchDocuments, setIsDocumentsLoading } = useFetchDocuments();
  const [ isUploading, setIsUploading]  = useState(false);
  const { user,author } = useSelector((state: RootState) => state.authUser);
  const { projectDetails } = useSelector((state: RootState) => state.document);
  
  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  const handleFilesSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setLoading(true);
    setIsUploading(true);
    for (let i = 0; i < files.length; i++) {
      try {
        const filePath = `${files[i].name}`;
        if (projectId) {
          const { data } = await uploadFileFolderService.getUploadSignedUrl(filePath,projectDetails.source_details);
          setPollingCounter(30);
          await uploadFileFolderService.uploadFiles(data.presignedUrl.data.signedUrl, files[i]);
          setPollingCounter(60);
          const {data:globalconfig}= await uploadFileFolderService.getGlobalConfig()
          setPollingCounter(80);
          await uploadFileFolderService.startFileProcessing(author.client_id,projectId, filePath, projectDetails.entity_id,globalconfig[0].value,projectDetails.source_details);
          setPollingCounter(100);
          openNotificationWithIcon("", `${files[i].name} uploaded successfully!`, "success");
          setFiles([]);
        }
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setFiles([]);
        setLoading(false);
        setIsUploading(false)
        setPollingCounter(0);
      }
    }
  };

  return (
    <div className="attachment">
      <Dragger {...props}>
        <div className="attachment-uploadbtn">
          <div className="attachment-content">
            <CloudUploadOutlined style={{ fontSize: "60px" }} />
            <div className="attachment-content-text">
              <p>
                Drag & drop file here or <span>Browse File</span>
              </p>
              {acceptedFormats.length > 0 && (
                <p className="italic">
                  You can upload only {acceptedFormats.join(", ")} files. Max size 25mb
                </p>
              )}
            </div>
          </div>
          <Button
            style={{ marginTop: "12px" }}
            className="fill"
            onClick={handleFilesSubmit}
            disabled={files.length === 0 || isUploading}
            loading={isUploading}
          >
            Upload
          </Button>
        </div>
      </Dragger>
      {pollingCounter !== 0 && (
        <div style={{ display: "flex" }}>
          <Progress
            status="active"
            strokeLinecap="round"
            type="line"
            style={{ marginBottom: "4px" }}
            percent={pollingCounter}
          />
        </div>
      )}
    </div>
  );
};
