import { apiClient } from "./apiClient";

const fetchQAHistory = async (userId: number) => {
  const res = await apiClient.get(`/qa/history/${userId}`);
  return res.data;
};

export const qaService = {
  fetchQAHistory,
};
