import jsPDF from "jspdf";
import { isEmptyObject } from "./objectOperations";
import { Document, Paragraph, Packer } from "docx";
import { message } from "antd";
import { openNotificationWithIcon } from "./notification";
import JSZip from 'jszip';

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.map(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;

export const mergeQueryStrings = (qsArray: string[]): string => {
  if (!qsArray.length) return "";
  return qsArray.join("&");
};

export const getQueryStringFromPaginationObj = (obj: IPagination): string => {
  let qs = "";
  if (isEmptyObject(obj)) return qs;
  const qsArray = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  qs = mergeQueryStrings(qsArray);
  return qs;
};

export const getOffsetFromPageAndPageSize = (page: number, pageSize: number) =>
  (page - 1) * pageSize;

export const appendTabToRouteQuery = (selectedTab: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("tab", selectedTab);
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, "", newUrl);
};

export const createTxtFile = (filename: string, content: string) => {
  const file = new File([content], `${filename}.txt`, { type: "text/plain" });
  return file;
};

export const formatLargeNumber = (number: number) => {
  if (number < 1000) {
    return number.toString(); // Numbers less than 1,000 are returned as-is.
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + "k"; // Thousands, one decimal place (e.g., 3.5k).
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(1) + "M"; // Millions, one decimal place (e.g., 2.7M).
  } else {
    return (number / 1000000000).toFixed(1) + "B"; // Billions, one decimal place (e.g., 1.2B).
  }
};

export const handlePdfDownload = (content: string, heading: string, fileName: string) => {
  const newFileName = "Auth_Decision_" + fileName.replace(/\.\w+$/, "").replace(/ /g, "_");
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  const regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b|[\][\]]/gi;

  const cleanedContent = content.replace(regex, "");

  const fullContent = `${heading}:\n\n${cleanedContent.trim()}`;

  const splitText = doc.splitTextToSize(fullContent, 230);

  doc.setFontSize(16);
  doc.text(heading, 10, 10);
  doc.setFontSize(12);

  let yPosition = 20; 

  splitText.forEach((text: string) => {
    const textHeight = doc.getTextDimensions(text).h;
    if (yPosition + textHeight > doc.internal.pageSize.height - 10) {
      doc.addPage();
      yPosition = 10;
    }
    doc.text(text, 10, yPosition);
    yPosition += textHeight + 2;
  });

  doc.save(`${newFileName}.pdf`);
};

export const handleDocxDownload = (content: string, heading: string, fileName: string) => {
  const newFileName = "Auth_Decision_" + fileName.replace(/\.\w+$/, "").replace(/ /g, "_");

  const regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b|[\][\]]/gi;

  const cleanedContent = content.replace(regex, "");

  const paragraphs = cleanedContent
    .trim()
    .split("\n")
    .map((line) => new Paragraph(line));

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            text: heading,
            heading: "Heading1",
          }),
          ...paragraphs,
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.download = `${newFileName}.docx`;
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};


export const handleCopyToClipboard = (content: string, heading: string) => {

  const regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b|[\][\]]/gi;

  const cleanedContent = content.replace(regex, "");

  navigator.clipboard.writeText(cleanedContent).then(
    () => {
      message.success(`${heading} copied to clipboard`);
    },
    () => {
      message.error(`Failed to copy ${heading}`);
    }
  );
};

// Function to insert a space before each uppercase letter 
// And capitalize the first letter of each word

export const camelToTitleCase = (input: string) => {
  const spacedString = input.replace(/([a-z])([A-Z])/g, "$1 $2");

  const titleCaseString = spacedString
    .toLowerCase()
    .replace(/(^|\s)\S/g, (char) => char.toUpperCase());

  return titleCaseString;
};


export const downloadPDF = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "download.pdf";

    if (contentDisposition) {
      const matches = /filename="?(.+)"?/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1];
      }
    } else {
      const urlParts = url.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      filename = lastPart.split("?")[0];
    }

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    openNotificationWithIcon("", "Download Failed", "error");
  }
};

export const handleZipFileDownload = async (
  contents: string[],
  headings: string[],
  fileNames: string[],
  fileUrl?: string
) => {
  const zip = new JSZip();

  const regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b|[\][\]]/gi;

  for (let i = 0; i < 3; i++) {
    const newFileName = "Auth_Decision_" + headings[i] + fileNames[i].replace(/\.\w+$/, "").replace(/ /g, "_");
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const cleanedContent = contents[i].replace(regex, "");
    const fullContent = `${headings[i]}:\n\n${cleanedContent.trim()}`;
    
    const splitText = doc.splitTextToSize(fullContent, 230);

    doc.setFontSize(16);
    doc.text(headings[i], 10, 10);
    doc.setFontSize(12);

    let yPosition = 20; 

    splitText.forEach((text: string) => {
      const textHeight = doc.getTextDimensions(text).h;
      if (yPosition + textHeight > doc.internal.pageSize.height - 10) {
        doc.addPage();
        yPosition = 10;
      }
      doc.text(text, 10, yPosition);
      yPosition += textHeight + 2;
    });

    zip.file(`${newFileName}.pdf`, doc.output('blob'));
  }

  if (fileUrl) {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Failed to download the file");
      const blob = await response.blob();
      const fileNameFromUrl = fileNames[0]; 
      zip.file(fileNameFromUrl, blob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const zipContent = await zip.generateAsync({ type: "blob" });

  const url = URL.createObjectURL(zipContent);
  const a = document.createElement('a');
  a.href = url;
  a.download = `Auth_Decisions_${fileNames[0]}.zip`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
const arrayIterate = (propertyValue: any, func: any): any[] => {
  const arrItem: any[] = [];
  for (let i = 0; i < propertyValue.length; i += 1) {
    if (
      typeof propertyValue[i] === "string" ||
      typeof propertyValue[i] === "number" ||
      typeof propertyValue[i] === "boolean"
    ) {
      arrItem.push(propertyValue[i]);
    } else {
      const prop = func(propertyValue[i]);
      arrItem.push(prop);
    }
  }
  return arrItem;
};

const toCamel = (property: string): string => {
  let camel = "";
  const propertyArray = property.split("_");
  for (let i = 0; i < propertyArray.length; i += 1) {
    let propertyValue = propertyArray[i];
    if (i > 0) {
      propertyValue = propertyValue.charAt(0).toUpperCase() + propertyValue.slice(1);
    }
    camel += propertyValue;
  }
  return camel;
};

export const snakeToCamel = (snake: any) => {
  let camel: any = {};
  if (Array.isArray(snake)) {
    camel = arrayIterate(snake, snakeToCamel);
  } else if (typeof snake === "string" || typeof snake === "number" || typeof snake === "boolean") {
    camel = snake;
  } else {
    Object.keys(snake).forEach((property) => {
      let propertyValue = snake[property];
      if (Array.isArray(propertyValue)) {
        propertyValue = arrayIterate(propertyValue, snakeToCamel);
      }
      camel[toCamel(property)] = snakeToCamel(propertyValue);
    });
  }
  return camel;
};
