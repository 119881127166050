import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../hooks";
import { RootState, setProjectDetails, updateDeleteConfirmation } from "../../store";
import { PAGE_URL, SORT_OPTIONS, SortByType } from "../../utils";
import { Button, Input, Select } from "antd";
import "./project-container.scss";
import { updateCanFetchContainers } from "../../store/file-folder-upload/slice";
import { ListDataIcon, TwoByTwoTileOption } from "../../assets/icons";
import { IProject } from "../../interfaces/project";
import { VIEW_STYLE } from "../../utils";
import ProjectsDataContainer from "../../containers/projects-data-container/ProjectsDataContainer";

interface Props {
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProjectName: React.Dispatch<React.SetStateAction<string>>;
  setIsPriorAuthProject: React.Dispatch<React.SetStateAction<boolean>>;
}

const debounce = <F extends (...args: any[]) => any>(func: F, delay: number) => {
  let timeoutId: NodeJS.Timeout | undefined;
  return (...args: Parameters<F>) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const ProjectsTable = ({
  setSelectedProjectId,
  setSelectedProjectName,
  setIsPriorAuthProject,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteProject, fetchProjects, loading, projects } = useProject();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const { canFetchContainers } = useSelector((state: RootState) => state.fileFolderUpload);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [viewStyle, setViewStyle] = useState(VIEW_STYLE.TILES);
  const sortedProjects = projects?.sort((a: any, b: any) => {
    if (a.is_pinned && !b.is_pinned) return -1;
    if (!a.is_pinned && b.is_pinned) return 1;
    return 0;
  });
  const debouncedSearch = useCallback(
    debounce((term: string) => {
      if (sortBy.indexOf("-") !== -1) {
        const sortOrder = -1;
        const newSortBy = sortBy.slice(1, sortBy.length);
        fetchProjects(term, newSortBy, sortOrder);
      } else {
        const sortOrder = 1;
        fetchProjects(term, sortBy, sortOrder);
      }
    }, 500),
    [fetchProjects]
  );

  const handlesearch = (val: string) => {
    setSearchTerm(val);
    debouncedSearch(val);
  };

  useEffect(() => {
    dispatch(updateCanFetchContainers(true));
  }, [dispatch]);

  useEffect(() => {
    if (canFetchContainers) {
      if (sortBy.indexOf("-") !== -1) {
        const sortOrder = -1;
        const newSortBy = sortBy.slice(1, sortBy.length);
        fetchProjects(searchTerm, newSortBy, sortOrder);
      } else {
        const sortOrder = 1;
        fetchProjects(searchTerm, sortBy, sortOrder);
      }
     dispatch(updateCanFetchContainers(false));
    }
  }, [canFetchContainers, dispatch,sortBy]);

  useEffect(() => {
    const deleteProj = async () => {
      await deleteProject(projectToBeDeleted);
      dispatch(updateDeleteConfirmation(false));
      dispatch(updateCanFetchContainers(true));
    };
    if (isDeleteConfirmed) {
      deleteProj();
    }
  }, [deleteProject, dispatch, isDeleteConfirmed, projectToBeDeleted]);

  const handleSortChange = (val: string) => {
    setSortBy(val);
    if (sortBy.indexOf("-") !== -1) {
      const sortOrder = -1;
      const newSortBy = sortBy.slice(1, sortBy.length);
      fetchProjects(searchTerm, newSortBy, sortOrder);
    } else {
      const sortOrder = 1;
      fetchProjects(searchTerm, sortBy, sortOrder);
    }
  };


  const handleProjectClick = (project: IProject) => {
    setSelectedProjectId(project.id);
    setSelectedProjectName(project.name);

    dispatch(
      setProjectDetails({
        guidelines: project?.guidelines[0]?.name,
        description: project?.description,
        projectName: project?.name,
        entity_id: project?.entity_id,
        source_details: project?.source_details,
      })
    );
    navigate(`${PAGE_URL.PROJECTS}/${project.id}`, {
      state: {
        selectedProjectId: project.id,
        selectedProjectName: project.name,
        isPriorAuthProject: project.isPriorAuth ? true : false,
      },
    });
    if (project.isPriorAuth) setIsPriorAuthProject(true);
    else setIsPriorAuthProject(false);
  };

  return (
    <div className="project-container">
      <div className="flex aic jcsb m-b">
        <div className="flex gp">
          <h1 className="no-padding">Projects</h1>
          <div className="flex gp link-color">
            <TwoByTwoTileOption
              className={`view-control cursor-pointer ${
                viewStyle === VIEW_STYLE.TILES ? "active" : ""
              } `}
              onClick={() => setViewStyle(VIEW_STYLE.TILES)}
            />
            <ListDataIcon
              className={`view-control cursor-pointer ${
                viewStyle === VIEW_STYLE.LIST ? "active" : ""
              } `}
              onClick={() => setViewStyle(VIEW_STYLE.LIST)}
            />
          </div>
        </div>
        <div className="flex gp aic">
          <Input
            placeholder="Search"
            size="large"
            style={{ width: "20em" }}
            value={searchTerm}
            onChange={(e) => handlesearch(e.target.value)}
          />

          <Select
            className="sort-Project"
            placeholder="Sort by"
            style={{ width: 120 }}
            popupMatchSelectWidth={false}
            placement={"bottomLeft"}
            value={sortBy}
            onChange={handleSortChange}
            options={SORT_OPTIONS}
          />

          <Button
            className="outline"
            onClick={() => {
              navigate(PAGE_URL.CREATE_PROJECT);
            }}
          >
            Create New Project
          </Button>
        </div>
      </div>

      <ProjectsDataContainer
        viewStyle={viewStyle}
        sortedProjects={sortedProjects}
        handleProjectClick={handleProjectClick}
        loading={loading}
      />
    </div>
  );
};

export default ProjectsTable;
