import React, { useEffect, useState } from "react";
import { Collapse, Input, Select, Checkbox, Button, Form, Space } from "antd";
import { useForm, Controller } from "react-hook-form";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import "./editProjectContainer.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../utils";
import { projectService, settingsService } from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const { Panel } = Collapse;
const { TextArea } = Input;

export enum CREATE_PROJECTS {
  GENERAL_SETTINGS = "General Settings",
  ADVANCED_SETTINGS = "Guidelines Settings",
}

const LLM_MODELS = [
  {
    id: "66e297167cb4161cf2e1dd19",
    name: "gtp-4",
    description: "cool gpt model",
  },
];

const EditProjectContainer = (handleCanFetchContainers: any) => {
  const location = useLocation();
  const {
    selectedProjectId,
    selectedProjectName,
    selectedProjectsDescription,
    selectedProjectGuidelines,
    selectedllmModel
  } = location.state;
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(CREATE_PROJECTS.GENERAL_SETTINGS);
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "all",
    defaultValues: {
      name: selectedProjectName || "",
      description: selectedProjectsDescription || "",
    },
  });

  const handlePanelChange = (key: any) => {
    setActiveKey(key[0]);
  };

  const handleNext = () => {
    const panels = Object.values(CREATE_PROJECTS);
    const currentIndex = panels.indexOf(activeKey);

    if (currentIndex < panels.length - 1) {
      setActiveKey(panels[currentIndex + 1]);
    }
  };

  const onSubmit = async (payload: any) => {
    try {
      setSubmitting(true);
      await projectService.editProject(selectedProjectId, payload);
      openNotificationWithIcon("", "Project edited successfully", "success");
      navigate("/dashboard");
      handleCanFetchContainers();
    } catch (error: any) {
      openNotificationWithIcon("", error?.response?.data?.message, "error");
    } finally {
      setSubmitting(false);
    }
  };

  const renderGeneralSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.GENERAL_SETTINGS} key={CREATE_PROJECTS.GENERAL_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectName">Project Name</label>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => <Input {...field} placeholder="Enter Name" />}
          />
          {errors.displayName && errors.displayName.type === "required" && (
            <span className="error">* Project name is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="projectDescription">Project Description</label>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <TextArea {...field} placeholder="Enter Description" style={{ height: "120px" }} />
            )}
          />
          {errors.description && errors.description.type === "required" && (
            <span className="error">* Project Description is required</span>
          )}
        </div>
        <div className="form-button">
          <Button onClick={handleNext} className="outline">
            Next
          </Button>
        </div>
      </Panel>
    );
  };

  const renderAdvancedSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.ADVANCED_SETTINGS} key={CREATE_PROJECTS.ADVANCED_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectName">Choose Guidelines for Adjudication</label>
          <Controller
            name="guidelinesId"
            control={control}
            render={({ field }: any) => (
              <Select
                {...field}
                disabled
                defaultActiveFirstOption
                defaultValue={selectedProjectGuidelines[0].id}
                placeholder="Select Guidelines"
              >
                {selectedProjectGuidelines.map((guideline: any) => (
                  <Select.Option key={guideline.id} value={guideline.id}>
                    {guideline.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="form-item">
          <label htmlFor="projectName">Choose LLM Model</label>
          <Controller
            name="modelId"
            control={control}
            render={({ field }: any) => (
              <Select
                {...field}
                disabled
                defaultActiveFirstOption
                defaultValue={selectedllmModel[0].id}
                placeholder="Select LLM Model"
              >
                {selectedllmModel.map((model: any) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
      </Panel>
    );
  };

  const allFieldsFilled = () => {
    const values = watch();
    return values.name && values.description;
  };

  const renderActionButtons = () => {
    return (
      <div className="create-form-button">
        <Button
          className="outline mr-10"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Cancel
        </Button>
        <Button
          className="fill"
          htmlType="submit"
          disabled={!allFieldsFilled()}
          loading={submitting}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-project-form-container flex-column ">
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={handlePanelChange}
        expandIconPosition="end"
        style={{ width: "70%" }}
        expandIcon={(p) =>
          p.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
        }
      >
        {renderGeneralSettingsPanel()}
        {renderAdvancedSettingsPanel()}
      </Collapse>
      {renderActionButtons()}
    </form>
  );
};

export default EditProjectContainer;
