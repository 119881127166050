import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PAGE_URL } from "../utils";
import { Spin, SpinProps } from "antd";

export const GuestLayout = (props: SpinProps) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [accessToken, navigate]);

  return (
    <div className="flex aic jcc" style={{height:"100vh"}}>
      <Spin size="large" {...props} ><></></Spin>
      <Outlet/>
    </div>
  );
};
