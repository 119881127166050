import { apiClient } from "./apiClient";

const getAllRoles = async (pageNumber = 1, pageSize = 10) => {
  const res = await apiClient.get(`/rbac/role?page=${pageNumber}&pageSize=${pageSize}`);
  return res.data;
};

const getAllPolicies = async (pageNumer = 1, pageSize = 10) => {
  const res = await apiClient.get(`/rbac/policy?page=${pageNumer}&pageSize=${pageSize}`);
  return res.data;
};

const getAllPermissions = async (pageNumer = 1, pageSize = 10) => {
  const res = await apiClient.get(`/rbac/permission?page=${pageNumer}&pageSize=${pageSize}`);
  return res.data;
};

const getAllResources = async (pageNumer = 1, pageSize = 10) => {
  const res = await apiClient.get(`/rbac/resource?page=${pageNumer}&pageSize=${pageSize}`);
  return res.data;
};

const validateLicenseKey = async (
  licenseKey: string
): Promise<{ data: { isValid: boolean; licenseKey: string }; status: string }> => {
  // const res = await apiClient.post<IValidateLicenseKeyResponse>("", { licenseKey });
  // return res;
  return { data: { isValid: true, licenseKey }, status: "" };
};

const getPermissions = async (roleId: number, limit: number, offset: number) => {
  const res = await apiClient.get<IGetPermissionResponseWrapper>(
    `/rbac/role/${roleId}/permissions?limit=${limit}&offset=${offset}`
  );
  return res;
};

const getRolesByCategoryId = async (id: number) => {
  const res = await apiClient.get<IResponseWrapper<ICopilotRoles>>(`/rbac/role/category/${id}`);
  return res.data;
};

const assignRoleToUser = async (userId: number, roleId: number, copilotId: string) => {
  // Make API call to assign role to user
  const res = await apiClient.post(`/rbac/role/assign-copilot-to-user`, {
    userId,
    roleId,
    copilotId,
  });
  return res.data;
};

const assignRoleToGroup = async (groupId: number, roleId: number, copilotId: string) => {
  // Make API call to assign role to group
  const res = await apiClient.post(`/rbac/role/assign-copilot-to-group`, {
    groupId,
    roleId,
    copilotId,
  });
  return res.data;
};

const assignClientRoleToUser = async (userId: number, roleId: number) => {
  // Make API call to assign role to user
  const res = await apiClient.post(`/rbac/role/assign-to-user`, {
    userId,
    roleId,
  });
  return res.data;
};

const assignClientRoleToGroup = async (groupId: number, roleId: number) => {
  // Make API call to assign role to user
  const res = await apiClient.post(`/rbac/role/assign-to-group`, {
    groupId,
    roleId,
  });
  return res.data;
};

const addRole = async ({ name, roleCategoryId, policyIds }: IAddRole) => {
  const postData: IAddRole = {
    name: name,
    roleCategoryId: roleCategoryId,
    policyIds: policyIds,
  };
  const res = await apiClient.post("/rbac/role/add", postData);
  return res;
};

const addPolicy = async ({ name, permissionIds, description, functionality }: IAddPolicy) => {
  const postData: IAddPolicy = {
    name: name,
    permissionIds: permissionIds,
    description: "",
    functionality: "",
  };
  const res = await apiClient.post("/rbac/policy/add", postData);
  return res;
};

const addPermission = async ({ name, description, operation, resource }: IAddPermission) => {
  const postData: IAddPermission = {
    name,
    description,
    operation,
    resource,
  };
  const res = await apiClient.post("/rbac/permission/add", postData);
  return res.data;
};

export const RBACService = {
  getAllRoles,
  validateLicenseKey,
  getAllPolicies,
  getAllPermissions,
  getPermissions,
  getAllResources,
  getRolesByCategoryId,
  assignRoleToUser,
  assignRoleToGroup,
  assignClientRoleToUser,
  assignClientRoleToGroup,
  addRole,
  addPolicy,
  addPermission,
};
