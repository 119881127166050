import { api, apiClient } from ".";
import { IAuthUser } from "../interfaces/authUser";

export interface IUserLoginRequest {
  email: string;
  password: string;
}

export interface IUserSignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IUserLoginResponse {
  data: IAuthUser;
  status: string;
}

export interface ILogoutResponse {
  data: string;
  status: string;
}

export interface IUserLoginTokenResponse {
  data: IAuthTokens;
  status: string;
}

export interface IAuthTokens {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

interface IUserForgotPasswordResponse {
  data: string;
  password: string;
}

interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  isInvited: boolean;
  isActive: boolean;
}

interface IUserSignUpResponse {
  data: IUser;
  status: string;
}

interface IAuthTokenResponse {
  response: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  };
  statusCode: number;
}

// interface IGenerateResetPasswordLinkResponse {
//   data: {
//     token: string;
//     link: string;
//   };
// }
interface IAccount {
  username: string;
  name: string;
  accessToken: string;
  tokenType: string;
  expiresOn: string;
  userId: number;
  realmName: string;
}

interface IRole {
  id: number;
  name: string;
}

interface IGetActiveDirectoryUserResponse {
  account: IAccount;
  groups: string[];
  role: IRole;
}

interface IGetActiveDirectoryUserResponseWrapper {
  data: IGetActiveDirectoryUserResponse;
  status: string;
}


const login = async (requestObject: IUserLoginRequest) => {
  const res = await apiClient.post<IUserLoginTokenResponse>(`/auth/login`, requestObject);
  return res;
};

const logout = async (request: number) => {
  const res = await apiClient.post<ILogoutResponse>(`/auth/logout/${request}`);
  return res;
};

const forgotPassword = async (reqObj: { email: string }) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    "/auth/forgot-password",
    reqObj
  );
  return res;
};

const resetPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/reset-password`,
    reqObj,
    {
      params: { token },
    }
  );
  return res;
};

const createPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/create-password`,
    reqObj,
    {
      params: { token },
    }
  );
  return res;
};

const generateAccessToken = async (refreshToken: string, emailId: string) => {
  try {
    const res = await api.post(`/auth/refresh-token/${emailId}`, {
      refreshToken,
    });
    return res;
  } catch(err) {
    return err;
  }
}
// const editSelf = async (reqObj: Partial<IEditUserRequestData>) => {
//   const res = await apiClient.put<IEditUserResponse>(`/auth/me/update`, reqObj);
//   return res;
// };

// const generateResetPasswordLink = async (email: string) => {
//   const res = await apiClient.post<IGenerateResetPasswordLinkResponse>(
//     `/auth/generate-reset-password-link`,
//     { email }
//   );
//   return res;
// };

const signUp = async (reqObject: IUserSignUpRequest) => {
  const res = await apiClient.post<IUserSignUpResponse>("/auth/signup", reqObject);
  return res;
};

const activeDirectoryAcquireToken = async (
  code: string,
  redirectURI: string,
  codeVerifier: string
) => {
  const res = await apiClient.post<IGetActiveDirectoryUserResponseWrapper>("/auth/requestToken", {
    code,
    redirectURI,
    codeVerifier,
  });
  return res;
};


export const authService = {
  login,
  forgotPassword,
  resetPassword,
  createPassword,
  signUp,
  logout,
  generateAccessToken,
  activeDirectoryAcquireToken
};
