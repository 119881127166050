import React, { useEffect, useState } from "react";
import Table, { TableProps } from "antd/lib/table";
import {
  DOCUMENT_STATUS,
  PAGE_URL,
  REVIEW_STATUS,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../utils";
import { useFetchDocuments } from "../../hooks";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { PdfViewer } from "../pdf-viewer/PdfViewer";
import { IGetSignedURLForFullSourceResponse, projectService, uploadFileFolderService } from "../../api";
import "./uploadFiles.scss";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../app-pagination/AppPagination";
import { Button, Dropdown, Menu } from "antd";
import { TextViewer } from "../TextViewer";
import TiffViewer from "../tiff-viewer/TiffViewer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
  projectId?: string;
  loading?: boolean;
}

interface IUploadedDocumentsDataType {
  id: string
  key: React.Key;
  fileFolder: string;
  created_at: string;
  updatedOn: string;
  processing_status: string;
  action: string;
  isFolder: boolean;
  document_uuid: string;
  no_of_pages: number;
  review_status: string;
}

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.PROCESSING:
      return "#ff9800";
    case DOCUMENT_STATUS.READY_WITH_SUMMARY:
    case DOCUMENT_STATUS.READY:
      return "#44c658";
    case DOCUMENT_STATUS.FAILED:
      return "#f44336";
  }
};

const getReviewClass = (value: string) => {
  switch (value) {
    case REVIEW_STATUS.NOT_REVIEWED:
      return "#ff9800";
    case REVIEW_STATUS.APPROVED:
    case REVIEW_STATUS.REQUESTED_FOR_MORE_INFO:
    case REVIEW_STATUS.FORWARDED_FOR_REVIEW:
      return "#44c658";
  }
};

export const UploadedFilesFolders = (props: Props) => {
  const { projectId, loading } = props;
  const navigate = useNavigate();
  const {
    fetchDocuments,
    documents,
    setIsDocumentsLoading,
    isDocumentsLoading,
    paginationObj,
    totalDocuments,
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [isTiffFile, setIsTiffFile] = useState(false);
  const { projectDetails } = useSelector((state: RootState) => state.document);

  const handlePreviewOnClick = async (
    path: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      const response = await uploadFileFolderService.getSignedUrlByFilepath(path, projectDetails.source_details);
      const data = response.data as IGetSignedURLForFullSourceResponse;
      const filepath = data.presignedUrl.data.signedUrl;
      setIsTiffFile(filepath.includes(".tiff"));
      setFileUrl(data.presignedUrl.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleRowClick = (record: IUploadedDocumentsDataType) => {
    // if (record.processing_status === DOCUMENT_STATUS.READY_WITH_SUMMARY) {
      navigate(`${PAGE_URL.PROJECTS}/${projectId}${PAGE_URL.CASE_DETAILS}/${record.id}`, {
        state: { rowData: record, projectId: projectId },
      });
    // } else if (record.processing_status === DOCUMENT_STATUS.READY) {
    //   openNotificationWithIcon("", "Please wait till the summary is generated", "info");
    // } else {
    //   openNotificationWithIcon(
    //     "",
    //     "The File is still processing or failed. Please try with completed files",
    //     "info"
    //   );
    // }
  };

  const handleRefreshClick = () => {
    fetchDocuments(projectId);
  };

  const getReviewText = (text: string) => {
    switch (text) {
      case REVIEW_STATUS.NOT_REVIEWED:
        return "Not Reviewed";
      case REVIEW_STATUS.APPROVED:
        return "Approved";
      case REVIEW_STATUS.REQUESTED_FOR_MORE_INFO:
        return "Requested more information";
      case REVIEW_STATUS.FORWARDED_FOR_REVIEW:
        return "Forwarded for review";
    }
  };

  const handleDeleteFile = async (id: string) => {
    try {
      const { data } = await uploadFileFolderService.deleteFile(id);
      fetchDocuments(projectId);
      openNotificationWithIcon("", "File deleted successfully", "success");
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };
  const getColumns: any = () => [
    {
      title: "Case ID",
      key: "document_uuid",
      dataIndex: "document_uuid",
      render: (_: string, record: any) => 
        (
        <span className="table-doc-id" onClick={() => handleRowClick(record)}>
          {record.id}
        </span>
      ),
    },
    {
      title: "No. of Pages",
      key: "no_of_pages",
      dataIndex: "no_of_pages",
      className: "dashboard-table-page-count",
    },
    {
      title: "Received Date",
      className: "dashboard-table-mid-size",
      key: "created_at",
      dataIndex: "created_at",
    },
    {
      title: "Processing Status",
      className: "dashboard-table-status",
      key: "processing_status",
      dataIndex: "processing_status",
      // filters: [
      //   {
      //     text: 'Ready',
      //     value: 'READY',
      //   },
      //   {
      //     text: 'Processing',
      //     value: 'PROCESSING',
      //   },
      //   {
      //     text: 'Uploaded',
      //     value: 'UPLOADED',
      //   },
      // ],
      // onFilter: (value: string | number | boolean, record: IUploadedDocumentsDataType) => 
      //   record.processing_status === value,
      render: (text: any, value: any) => (
        <>
          {value.processing_status && (
            <div>
              <div className="status" style={{ color: getClass(value.status) }}>
                <p style={{ color: getClass(value.processing_status) }}>{value.processing_status.replace(/_/g, " ")}</p>{" "}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Review Status",
      dataIndex: "review_status",
      className: "dashboard-table-mid-size",
      key: "review_status",
      render: (text: any, value: any) => (
        <>
          {value.review_status && (
            <div
              className="status"
              style={{ color: getReviewClass(value.reviewStatus || "NOT_REVIEWED") }}
            >
              <p style={{ color: getReviewClass(value.reviewStatus || "NOT_REVIEWED") }}>
                {value?.review_status || "NOT_REVIEWED"}
              </p>{" "}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Preview",
      className: "actions-tile",
      render: (text: any, value: any) => (
        <div
          className="dashboard-table-preview"
          onClick={(e) => handlePreviewOnClick(value.name, e)}
        >
          <EyeOutlined className="dashboard-table-preview-icon" title="Preview document" />
        </div>
      ),
    },
    {
      title: "Actions",
      className: "actions-tile",
      render: (value: any, record: any) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key === "delete") handleDeleteFile(record.id);
              }}
            >
              <Menu.Item key="delete">
                <DeleteOutlined /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="dashboard-table-actions">
            <MoreOutlined className="dashboard-table-actions-icon" title="More options" />
          </div>
        </Dropdown>
      ),
      align: "center",
    },
  ];

  // const onChange: TableProps<IUploadedDocumentsDataType>['onChange'] = (pagination, filters, sorter, extra) => {
  //   console.log('params', pagination, filters, sorter, extra);
  // };

  useEffect(() => {
    if (projectId && !loading) {
      fetchDocuments(projectId);
    }
  }, [projectId, loading, page, pageSize]);

  const filteredData = documents?.filter((item) =>
    item.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const data: IUploadedDocumentsDataType[] = filteredData?.filter((item) => item.name !== "")?.map((item, index) => ({
      id: item.id,
      action: "",
      isFolder: item.isFolder,
      updatedOn: getLocalDateTimeStringFromISO(item?.updatedAt),
      created_at: getLocalDateTimeStringFromISO(item?.created_at),
      fileFolder: item.name,
      key: index,
      processing_status: item?.processing_status?.toUpperCase(),
      name: item.name,
      document_uuid: item.document_uuid,
      no_of_pages: item.no_of_pages,
      review_status: item?.review_status,
    }));

  const renderFileViewer = () => {
    switch (isTiffFile) {
      case true:
        return (
          <TiffViewer
            tiff={fileUrl}
            pageInfo={{ height: 623, width: 400, scale: 1 }}
            setCurrPage={setCurrentPage}
            currPage={currentPage}
          />
        );
      case false:
        return (
          <PdfViewer
            pageInfo={{ height: 623, width: 400 }}
            pdf={fileUrl}
            displayPagination
            setCurrPage={setCurrentPage}
            currPage={currentPage}
            sourcePopup={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-table">
      {/* TODO- TEMPORARY */}

      {/* <h3>Recent Cases</h3> */}
      {/* <Input
        placeholder="Search file name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}
      <div className="flex gp justify-end">
        <AppPagination
          className="tr m-b paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: totalDocuments,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
        <Button
          disabled={loading}
          className="fill bold"
          title="refresh"
          onClick={handleRefreshClick}
        >
          <ReloadOutlined /> Refresh
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <Table
          rowClassName={"cursor-pointer"}
          columns={getColumns()}
          pagination={false}
          dataSource={data}
          loading={isDocumentsLoading}
          // onChange={onChange}
        />
        {fileUrl && (
          <div className="pdf-viewer">
            {renderFileViewer()}
            <CloseOutlined className="pdf-viewer-closeicon" onClick={() => setFileUrl("")} />
          </div>
        )}
      </div>
    </div>
  );
};
