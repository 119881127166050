import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { AppHeader, AppMenu, PageHeader } from "../containers";
import { PAGE_URL } from "../utils";
import { useAuthUser } from "../hooks";
import { store } from "../App";

const initialPageHeader = { pageTitle: "", displayBackButton: false, pageRoute: "" }

export const PrivateLayout = () => {
  const user = store.getState().authUser.user;
  const [pageHeader, setPageHeader] = useState<PageHeader>(initialPageHeader);

  const resetPageHeader = () => {
    setPageHeader(initialPageHeader)
  };

  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) window.location.replace(PAGE_URL.LOGIN);
  // else window.location.replace(PAGE_URL.DASHBOARD)
  if (window.location.href.includes(PAGE_URL.FORBIDDEN)) {
    return (
      <Layout>
        <Outlet/>
      </Layout>
    )
  } else if (user && user.role && !user.role.length) {
    window.location.replace(PAGE_URL.FORBIDDEN);
  }
  return (
    <Layout>
      <AppMenu />
      <Layout>
        <AppHeader pageHeader={pageHeader} setPageHeader={setPageHeader} />
        <Outlet context={{ pageHeader, setPageHeader, resetPageHeader }} />
        {/* <AppFooter /> */}
      </Layout>
    </Layout>
  );
};

export interface IOutletContext {
  setPageHeader: (header: PageHeader) => void;
  pageHeader: PageHeader;
  resetPageHeader: () => void;
}