import React, { useState, useEffect } from "react";
import { useLocation, useParams, useOutletContext } from "react-router-dom";
import { CopilotContainer, PageHeader, PageWrapper } from "../containers";
import { PAGE_URL } from "../utils/constants";
import { IOutletContext } from "../navigation/PrivateLayout";

export const Project = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { selectedProjectName } = location.state || {};
  const { projectId } = useParams();
  
  const { setPageHeader, resetPageHeader } = useOutletContext<IOutletContext>();

  useEffect(() => {
    if (setPageHeader && resetPageHeader) {
      setPageHeader({
        pageRoute: `${PAGE_URL.PROJECTS}/${projectId}`,
        pageTitle: selectedProjectName,
        displayBackButton: true,
      });
      return () => resetPageHeader();
    }
  }, [projectId, setPageHeader, selectedProjectName]);

  return <PageWrapper loading={isLoading} pageContainerChildren={<CopilotContainer />} />;
};

export default Project;