import React from "react";
import "./project-details.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const ProjectDetails = () => {
  const { projectDetails } = useSelector((state: RootState) => state.document)
  return (
    <div className="attachment project-details flex gp">
      <div className="project-details-colomn">
        <p className="project-details-title">Description:</p>
        <p className="project-details-description">
          {projectDetails.description}
        </p>
      </div>

      {/* Vertical Divider */}
        <div className="project-details-divider"></div>

      <div className="project-details-colomn">
        <p className="project-details-title">Guidelines:</p>
        <p className="project-details-description">{projectDetails?.guidelines}</p>
      </div>
    </div>
  );
};
