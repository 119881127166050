import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { ExpandPdf } from "./ExpandPdf";

type StateProps = {
  visibility: boolean;
  fileName: string;
  directoryPath: string;
  projectId: string;
};

type DispatchProps = {
  handleClose: () => void;
};

export type ExpandPdfProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.EXPAND_PDF],
  fileName: state.fileFolderUpload.targetFileFolder,
  directoryPath: state.fileFolderUpload.directoryPath,
  projectId: state.fileFolderUpload.projectId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.EXPAND_PDF));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandPdf);
