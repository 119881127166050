import { apiClient } from ".";

export interface IGetGroupsUser {
  accessToken: string;
  refreshToken: string;
}

export interface IPaginationResponse<T> {
  data: {
    info: T[];
    totalCount: number;
  };
  status: number;
}

interface IRole {
  id: number;
  name: string;
}
export interface IGetUsersResponse {
  id: number;
  firstName: string;
  lastName: string;
  status: number;
  roles: IRole[];
  userContact: {
    email: string;
  };
}

export interface IAddUser {
  firstName: string;
  lastName: string;
  clientId: number;
  roleId: number;
  userContact: {
    email: string;
  };
}

interface IRequest {
  firstName: string;
  lastName: string;
  clientId: number;
  roleId: number;
  email: string;
}

interface IKeycloakRequest {
  refreshToken: string;
  realmName: string;
  email: string;
}

const getUsers = async (copilotId = "", pageNumber = 1, pageSize = 100, searchTerm = "") => {
  let route = `/user?page=${pageNumber}&pageSize=${pageSize}`;
  if (searchTerm) {
    route = route + "&searchTerm=" + searchTerm;
  }
  if (copilotId) {
    route = route + "&copilotId=" + copilotId;
  }
  const res = await apiClient.get<IPaginationResponse<IGetUsersResponse>>(route, {});
  return res.data;
};

const addUser = async ({ firstName, lastName, email, roleId, clientId }: IRequest) => {
  const postData: IAddUser = {
    firstName: firstName,
    lastName: lastName,
    clientId: clientId,
    roleId: roleId,
    userContact: {
      email: email,
    },
  };
  const res = await apiClient.post("/user", postData);
  return res;
};

const getUserByEmail = async (emailId: string) => {
  let route = "/user/email/";
  if (emailId) {
    route = route + emailId;
  }
  try {
    const res = await apiClient.get(route, {});
    return res.data;
  } catch (err: any) {
    return err.response;
  }
};

const keycloakLogin = async ({ refreshToken, realmName, email }: IKeycloakRequest) => {
  const postData = {
    refreshToken,
    realmName,
    email,
  };
  const res = await apiClient.post("/keycloakLogin", postData);
  return res.data;
};

export const userService = {
  getUsers,
  addUser,
  getUserByEmail,
  keycloakLogin,
};
