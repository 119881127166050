import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { store } from "../App";
import { logoutUser,setKeyTokens } from "../store";
import { PAGE_URL, snakeToCamel } from "../utils";
import { ActiveDirectoryService } from "./activeDirectoryAuth";
import { envConfig } from "../config/env";
import { authService } from "./auth";

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "authorization";
  const privateNamespace = "x-user-token";
  headers[namespace] = `Bearer ${token}`;
  headers[privateNamespace] = "true";
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = localStorage.getItem("accessToken");
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: envConfig.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

const authOptions = {
  baseURL: envConfig.authBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

const apiClient = axios.create(defaultOptions);
const authApiClient = axios.create(authOptions)
const api = axios.create(defaultOptions);

apiClient.interceptors.request.use(
  (config: any) => {
    const clientId = store.getState().authUser.user.clientId
    if (config.baseURL?.includes(":clientId")) {
    config.baseURL = config.baseURL.replace(":clientId", clientId);
    }
    config.headers = withAuthHeaders({}) as AxiosRequestHeaders;
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  async (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (pathname !== PAGE_URL.LOGIN && error.response.status === 401) {
      // store.dispatch(logoutUser());
      // window.location.replace(PAGE_URL.LOGIN);
      localStorage.setItem("redirectPath", window.location.pathname);
      // we need to get the userId from store
      const user = store.getState().authUser.user;
      const keycloak = store.getState().authUser.keycloak;
      if (keycloak?.refreshToken && envConfig.viteKeycloakBaseUrl) {
        const response = (await authService.generateAccessToken(
          keycloak.refreshToken,
          user.email
        )) as any;
        if (response?.status === 200) {
          const responseData = snakeToCamel(response.data.response);
          store.dispatch(setKeyTokens(responseData));
          localStorage.setItem("accessToken", responseData.accessToken);
          window.location.reload();
        } else {
          localStorage.clear();
          const keycloakLogoutUrl =
            `${envConfig.viteKeycloakBaseUrl}realms/${
              keycloak.realm
            }/protocol/openid-connect/logout` +
            "?id_token_hint=" +
            keycloak.idToken +
            "&post_logout_redirect_uri=" +
            encodeURIComponent(window.location.origin);
          window.location.replace(keycloakLogoutUrl);
        }
      } else {
        localStorage.clear();
        window.location.replace(window.location.origin);
      }
    }
    if (pathname !== PAGE_URL.LOGIN && error.response.status === 403) {
      window.location.replace(`${window.location.origin}/forbidden`);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    if (error.response.status !== 401) {
      error.response.message = "";
    }
    return Promise.reject(error);
  }
);


export { apiClient, authApiClient ,api};
