import React from "react";
import { IconsProps } from "./Icons";

export const SecurityIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 101 100"}
      width={width ? width : "101"}
      height={height ? height : "100"}
      fill={fill ? fill : "currentColor"}
    >
      <path d="M50.5 91.6664C40.8472 89.2358 32.8778 83.6969 26.5917 75.0497C20.3056 66.4025 17.1639 56.8025 17.1667 46.2497V20.833L50.5 8.33301L83.8334 20.833V46.2497C83.8334 46.9441 83.8167 47.6386 83.7834 48.333C83.75 49.0275 83.6972 49.7219 83.625 50.4163C83 50.2775 82.3584 50.1733 81.7 50.1038C81.0417 50.0344 80.3639 49.9997 79.6667 49.9997C78.9028 49.9997 78.1736 50.0344 77.4792 50.1038C76.7847 50.1733 76.0556 50.2775 75.2917 50.4163C75.3611 49.7219 75.4139 49.0455 75.45 48.3872C75.4861 47.7288 75.5028 47.0163 75.5 46.2497V26.5622L50.5 17.1872L25.5 26.5622V46.2497C25.5 54.6525 27.8611 62.2913 32.5834 69.1663C37.3056 76.0414 43.2778 80.6247 50.5 82.9163C51.9584 82.4302 53.382 81.84 54.7709 81.1455C56.1597 80.4511 57.5139 79.6525 58.8334 78.7497V88.5413C57.5139 89.2358 56.1597 89.8441 54.7709 90.3663C53.382 90.8886 51.9584 91.3219 50.5 91.6664ZM70.7084 91.6664C69.7361 91.6664 68.9028 91.3191 68.2084 90.6247C67.5139 89.9302 67.1667 89.0969 67.1667 88.1247V74.3747C67.1667 73.4025 67.5139 72.5691 68.2084 71.8747C68.9028 71.1802 69.7361 70.833 70.7084 70.833H71.3334V66.6663C71.3334 64.3747 72.15 62.4136 73.7833 60.783C75.4167 59.1525 77.3778 58.3358 79.6667 58.333C81.9556 58.3302 83.9181 59.1469 85.5542 60.783C87.1903 62.4191 88.0056 64.3802 88 66.6663V70.833H88.625C89.5972 70.833 90.4306 71.1802 91.125 71.8747C91.8195 72.5691 92.1667 73.4025 92.1667 74.3747V88.1247C92.1667 89.0969 91.8195 89.9302 91.125 90.6247C90.4306 91.3191 89.5972 91.6664 88.625 91.6664H70.7084ZM75.5 70.833H83.8334V66.6663C83.8334 65.4858 83.4333 64.4969 82.6334 63.6997C81.8334 62.9025 80.8445 62.5025 79.6667 62.4997C78.4889 62.4969 77.5 62.8969 76.7 63.6997C75.9 64.5025 75.5 65.4913 75.5 66.6663V70.833Z" fill="#5F3CAB"/>
    </svg>
  );
};
